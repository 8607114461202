<template>
    <div class="main platforms">
        <div class="header__main ">
            <div class="header__body main-width">
                <h2 class="header__title text-center"> {{ $t("PlatformsPage.title") }} </h2>
            </div>
        </div>
        <section class="main-width">
            <div class="row platform-area">
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="platform">
                        <div class="platform-header">
                            <div class="platform-header__title">
                                <h4> {{ $t("PlatformsPage.goszakup") }} </h4>
                            </div>
                            <div class="platform-header__img">
                                <img src="@/assets/img/icons/goszakupki.svg" alt="">
                            </div>
                        </div>
                        <div class="platform-body">
                            <p>
                                <a href="https://wiki.goszakup.gov.kz/pages/viewpage.action?pageId=327707"> {{ $t("login.registration_btn") }} </a> / 
                                <a href="https://v3bl.goszakup.gov.kz/ru/sign_workaround/not_installed"> {{ $t("navigation.login") }} </a>
                            </p>
                            <p>
                                <a href="https://eoz.kz/#!/docview?=api%2Fuicommand%2Fdownload%2Ffile%3Fid%3D0058B138-3D25-4031-A0E7-17716116BC06">
                                    {{ $t("regulations.rules") }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="platform">
                        <div class="platform-header">
                            <div class="platform-header__title">
                                <h4> {{ $t("PlatformsPage.samruk") }} </h4>
                            </div>
                            <div class="platform-header__img">
                                <img src="@/assets/img/icons/skk.png" alt="">
                            </div>
                        </div>
                        <div class="platform-body">
                            <p>
                                <a href="https://zakup.sk.kz/#/int/register"> {{ $t("login.registration_btn") }} </a> / 
                                <a href="https://zakup.sk.kz/#/ext"> {{ $t("navigation.login") }} </a>
                            </p>
                            <p>
                                <a href="https://eoz.kz/#!/docview?url=api%2Fuicommand%2Fdownload%2Ffile%3Fid%3DF4BB0353-C64E-410A-9E1F-66B97CFF65FA">
                                    {{ $t("regulations.rules") }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="platform">
                        <div class="platform-header">
                            <div class="platform-header__title">
                                <h4> {{ $t("PlatformsPage.nadloc") }} </h4>
                            </div>
                            <div class="platform-header__img">
                                 <img src="@/assets/img/icons/nadloc.svg" alt="">
                            </div>
                        </div>
                        <div class="platform-body">
                            <p>
                                <a href="http://reestr.nadloc.kz/ru/user/registration"> {{ $t("login.registration_btn") }} </a> / 
                                <a href="http://reestr.nadloc.kz/ru/user/login"> {{ $t("navigation.login") }} </a>
                            </p>
                            <p>
                                <a href="https://eoz.kz/#!/docview?url=api%2Fuicommand%2Fdownload%2Ffile%3Fid%3DF61B80C8-CDB5-46B4-83C9-5BA1FD85037F">
                                    {{ $t("regulations.rules") }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="platform">
                        <div class="platform-header">
                            <div class="platform-header__title">
                                <h4> Mitwork </h4>
                            </div>
                            <div class="platform-header__img">
                                <img src="@/assets/img/icons/mitwork.png" alt="">
                            </div>
                        </div>
                        <div class="platform-body">
                            <p>
                                <a href="https://eep.mitwork.kz/ru/pages/show/reg"> {{ $t("login.registration_btn") }} </a> / 
                                <a href="https://eep.mitwork.kz/ru/user/login"> {{ $t("navigation.login") }} </a>
                            </p>
                            <p>
                                <a href="https://eoz.kz/#!/docview?url=api%2Fuicommand%2Fdownload%2Ffile%3Fid%3D8DE10F15-F2E1-4610-999C-487C99F1670F">
                                    {{ $t("regulations.rules") }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="platform">
                        <div class="platform-header">
                            <div class="platform-header__title">
                                <h4> MP.KZ </h4>
                            </div>
                            <div class="platform-header__img">
                                <img src="@/assets/img/icons/mpkz.svg" alt="">
                            </div>
                        </div>
                        <div class="platform-body">
                            <p>
                                <a href="https://mp.kz/registration"> {{ $t("login.registration_btn") }} </a> / 
                                <a href="https://mp.kz/?Login=me"> {{ $t("navigation.login") }} </a>
                            </p>
                            <p>
                                <a href="https://mp.kz/support/">
                                    {{ $t("regulations.rules") }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="platform">
                        <div class="platform-header">
                            <div class="platform-header__title">
                                <h4> EurasianTech-Tender </h4>
                            </div>
                            <div class="platform-header__img">
                                <img src="@/assets/img/icons/eurasiantech.png" alt="">
                            </div>
                        </div>
                        <div class="platform-body">
                            <p>
                                <a href="https://eurasiantech-tender.kz/Identity/Account/Register"> {{ $t("login.registration_btn") }} </a> / 
                                <a href="https://eurasiantech-tender.kz/Identity/Account/Login"> {{ $t("navigation.login") }} </a>
                            </p>
                            <p>
                                <a href="https://eurasiantech-tender.kz/Home/RegistrationDocuments">
                                    {{ $t("regulations.rules") }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="platform">
                        <div class="platform-header">
                            <div class="platform-header__title">
                                <h4> CaspyTender </h4>
                            </div>
                            <div class="platform-header__img">
                                <img src="@/assets/img/icons/caspytender.png" alt="">
                            </div>
                        </div>
                        <div class="platform-body">
                            <p>
                                <a href="https://td.ccx.kz/registration"> {{ $t("login.registration_btn") }} </a> / 
                                <a href="https://idp.ccx.kz/auth/realms/istd/protocol/openid-connect/auth?client_id=angular-frontend&redirect_uri=https%3A%2F%2Ftd.ccx.kz%2Fcabinet&state=4cbd6c00-e0f4-4a5d-8735-c041c5119b70&response_mode=fragment&response_type=code&scope=openid&nonce=e3460630-05ff-4761-8c25-46644984e974"> {{ $t("navigation.login") }} </a>
                            </p>
                            <p>
                                <a href="https://caspytender.kz/wiki">
                                    {{ $t("regulations.rules") }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="platform">
                        <div class="platform-header">
                            <div class="platform-header__title">
                                <h4> e-port.kz </h4>
                            </div>
                            <div class="platform-header__img">
                                <!-- <img src="@/assets/img/icons/caspytender.png" alt=""> -->
                            </div>
                        </div>
                        <div class="platform-body">
                            <p>
                                <a href="https://e-port.kz/ru/Home/General#"> {{ $t("login.registration_btn") }} </a> / 
                                <a href="https://e-port.kz/ru/User/Login"> {{ $t("navigation.login") }} </a>
                            </p>
                            <p>
                                <a href="https://e-port.kz/ru/Instruction/List">
                                    {{ $t("regulations.rules") }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="platform">
                        <div class="platform-header">
                            <div class="platform-header__title">
                                <h4> Электронная торговая площадка ЕТБ </h4>
                            </div>
                            <div class="platform-header__img">
                                <img src="@/assets/img/icons/etbemp.png" alt="">
                            </div>
                        </div>
                        <div class="platform-body">
                            <p>
                                <a href="https://etbemp.kz/ru/co/registration"> {{ $t("login.registration_btn") }} </a> / 
                                <a href="https://etbemp.kz/ru/user/login"> {{ $t("navigation.login") }} </a>
                            </p>
                            <p>
                                <a href="https://etbemp.kz/Attach/instruction.pdf">
                                    {{ $t("regulations.rules") }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="platform">
                        <div class="platform-header">
                            <div class="platform-header__title">
                                <h4> ETS Тендер </h4>
                            </div>
                            <div class="platform-header__img">
                                <img src="@/assets/img/icons/etsTender.png" alt="">
                            </div>
                        </div>
                        <div class="platform-body">
                            <p>
                                <a href="https://www.ets-tender.kz/request/application.html"> {{ $t("login.registration_btn") }} </a> / 
                                <a href="https://www.ets-tender.kz/#auth_ajax_modal"> {{ $t("navigation.login") }} </a>
                            </p>
                            <p>
                                <a href="https://www.ets-tender.kz/training/">
                                    {{ $t("regulations.rules") }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="platform">
                        <div class="platform-header">
                            <div class="platform-header__title">
                                <h4> zakup-besk.kz </h4>
                            </div>
                            <div class="platform-header__img">
                                <img src="@/assets/img/icons/zakup-besk.png" alt="">
                            </div>
                        </div>
                        <div class="platform-body">
                            <p>
                                <a href="https://zakup-besk.kz/login"> {{ $t("login.registration_btn") }} </a> / 
                                <a href="https://zakup-besk.kz/login"> {{ $t("navigation.login") }} </a>
                            </p>
                            <p>
                                <a href="https://zakup-besk.kz/support2">
                                    {{ $t("regulations.rules") }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'Platforms'
    }
</script>

<style lang="scss" scoped>
.platform-area {
    padding-top: 40px;
}
</style>